exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-software-asset-library-js": () => import("./../../../src/pages/software/asset-library.js" /* webpackChunkName: "component---src-pages-software-asset-library-js" */),
  "component---src-pages-software-build-js": () => import("./../../../src/pages/software/build.js" /* webpackChunkName: "component---src-pages-software-build-js" */),
  "component---src-pages-software-dashboard-js": () => import("./../../../src/pages/software/dashboard.js" /* webpackChunkName: "component---src-pages-software-dashboard-js" */),
  "component---src-pages-software-proposal-security-js": () => import("./../../../src/pages/software/proposal-security.js" /* webpackChunkName: "component---src-pages-software-proposal-security-js" */),
  "component---src-pages-software-reporting-js": () => import("./../../../src/pages/software/reporting.js" /* webpackChunkName: "component---src-pages-software-reporting-js" */),
  "component---src-pages-software-training-js": () => import("./../../../src/pages/software/training.js" /* webpackChunkName: "component---src-pages-software-training-js" */),
  "component---src-pages-software-user-workflow-js": () => import("./../../../src/pages/software/user-workflow.js" /* webpackChunkName: "component---src-pages-software-user-workflow-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

